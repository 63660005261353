import React, { useState, useEffect } from 'react';
import './header.css';
import logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate(); 

    const handleNavigation = (event, path, sectionId) => {
        event.preventDefault();
        navigate(path);
        if (sectionId) {
            setTimeout(() => {
                const section = document.getElementById(sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); 
        } else {
            window.scrollTo(0, 0);
        }
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 150);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`headerCobater ${isScrolled ? 'scrolled' : ''}`}>
            <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
                <img className="logo" src={logo} alt="logo de l'entreprise" onClick={(e) => handleNavigation(e, '/')} />
                <button className={`burger-icon ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}></button>
                <div className={`menu ${menuOpen ? 'open' : ''}`}>
                    <ul className="liste"> 
                    <li><a href="/" onClick={(e) => handleNavigation(e, '/')}>Accueil</a></li>
                   
                        {/*<li><a href="/cobater" onClick={(e) => handleNavigation(e, '/cobater')}>Cobater</a></li>*/}
                        <li><a href="/" onClick={(e) => handleNavigation(e, '/', 'products')}>Produits</a></li>
                        <li><a href="/" onClick={(e) => handleNavigation(e, '/', 'services')}>Services</a></li>
                        <li><a href="/methodes" onClick={(e) => handleNavigation(e, '/methodes')}>Méthode</a></li>
                        <li><a href="/contact" onClick={(e) => handleNavigation(e, '/contact')}>Contact</a></li>
                    </ul>
                </div>
            </nav>
            <p className={`introduction ${isScrolled ? 'scrolled' : ''}`}>
                <span className="line">Notre domaine de compétence:</span>
                <span className="line">la valorisation des déchets végétaux</span>
            </p>
        </header>
    );
}

export default Header;
