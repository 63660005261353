import "./services.css"
import vente from "../img/vente.jpg"
import recyclage from "../img/recyclage.jpg"
import livraison from "../img/livraison.jpg"

function Services () {
    return (
        <div className="content services" id="services">
            <h2>Nos services</h2>
            <div className="servicesDisplay"> 
            <div className="title">
                <h3>Vente</h3>
                <img src={vente} alt="Pelle remplissant une benne de camion"/>
                <ul>
                    <li>Paillage 0/15</li>
                    <li>Paillage 15/70</li>
                    <li>Compost</li>
                    <li>Mélange terreau sablon</li>
                    <li>Terre amendée</li>
                </ul>
            </div>
            <div className="title">
                <h3>Recyclage</h3>
                <img src={recyclage} alt="Pelle remplissant le broyeur avec des branchages"/>
                <ul>
                    <li>Tontes et feuilles</li>
                    <li>Taille</li>
                    <li>Bois et souches</li>
                </ul>
            </div>
            <div className="title">
                <h3>Livraison</h3>
                <img src={livraison} alt="Camion de livraison"/>
                <ul>
                    <li>A partir de 50 m&sup3; bennes FMA</li>
                </ul>
            </div>
            </div>
        </div>    
)
}

export default Services;