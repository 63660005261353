import "./products.css";
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

// Import des images
import paillage from '../img/paillage.jpg';
import compost from '../img/compost.jpg';
import sablon from '../img/sablon.jpg';

function Products() {
    const [selectedButton, setSelectedButton] = useState(0);

    const handleClick = (index) => {
        setSelectedButton(index);
    };

    const buttonData = [
        {
            name: "Compost végétal",
            imageUrl: compost,
            text: `
**Compost végétal NF-U-44-051, Calibrage 0/15**

- Utilisation en agriculture biologique
- Conditionnement en vrac
- Enrichit le sol en humus, limite l'évaporation de l'eau, couvre sol, limite la levée des adventices`
        },
        {
            name: "Paillage organique",
            imageUrl: paillage,
            text: `
**Paillage NF-U-44-051, Calibrage 0/15 et 15/40**

- Utilisation en agriculture biologique
- Conditionnement en vrac
- Lutte contre l'érosion, enrichit le sol en humus, limite l'évaporation de l'eau, réduit les arrosages, limite la levée des adventices`
        },
        {
            name: "Compost/sablon",
            imageUrl: sablon,
            text: `
**NF-U-44-551**

- Conditionnement en vrac
- Utilisation pour le surfaçage de gazon, allégement des sols lourds`
        },
        {
            name: "Terre végétale",
            imageUrl: "", // Image non disponible
            text: `
**NF-U-44-551**

- Support de culture
- 70% de terre franche, 30% de compost végétal
- En apport pour réaliser des potagers, pour réussir une pelouse
- Conditionnement en vrac`
        }
    ];

    return (
        <div className="content products" id="products">
            <h2>Nos Produits</h2>
            <div className="buttons">
                {buttonData.map((button, index) => (
                    <button
                        key={index}
                        onClick={() => handleClick(index)}
                        className={index === selectedButton ? 'selected' : ''}
                        aria-pressed={index === selectedButton}
                    >
                        {button.name}
                    </button>
                ))}
            </div>
            {selectedButton !== null && (
                <div className="productsText">
                    {buttonData[selectedButton].imageUrl ? (
                        <img
                            className="productImg"
                            src={buttonData[selectedButton].imageUrl}
                            alt={buttonData[selectedButton].name}
                        />
                    ) : (
                        <div className="noImage">Pas d'image disponible pour le moment</div>
                    )}
                    <ReactMarkdown className="descriptionProduct">
                        {buttonData[selectedButton].text}
                    </ReactMarkdown>
                </div>
            )}
        </div>
    );
}

export default Products;
