import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Methods from './Methods/Methods';
import MentionsLegales from './Mentions Légales/MentionsLegales';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <Home/>            
        } />
        <Route path='/contact' element={<Contact/>}/>
        <Route path="/methodes" element={<Methods/>}/>
        <Route path="/mentions-legales" element={<MentionsLegales/>}/>


      </Routes>
      <Footer className="footer" />
    </Router>
  );
}

export default App;
