import "./contact.css";

function Contact() {
    return (
        <div className="layout contact">
            <h2>Contact et accès</h2>
            <div className="content contacts">
                <section className="infos">
                    <h3>Adresse</h3>
                    <p>Voie des Jumeaux 91320 Wissous</p>
                    <h3>Coordonnées GPS</h3>
                    <p>48.740862345598714, 2.339016810299329</p>
                    <h3>Mail</h3>
                    <a href="mailto:cobater@wanadoo.fr">cobater@wanadoo.fr</a>
                    <h3>Téléphone</h3>
                    <p>01 49 79 96 39</p>
                    <h3>Horaires</h3>
                    <h4>Lundi - Jeudi:</h4>
                    <p>7h30-12h</p>
                    <p>13h-16h30</p>
                    <h4>Vendredi:</h4>
                    <p>7h30-12h</p>
                    <p>13h-16h</p>
                    <h4>Fermetures annuelles:</h4>
                    <p>Du 12/08/2024 au 16/08/2024 inclus</p>
                    <p>Du 24/12/2024 au 03/01/2025 inclus</p>
                </section>
                <section className="map">
                    <p>Prendre la voie de droite direction déchetterie déchets verts</p>
                    <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1258.0830051501346!2d2.340327178591301!3d48.74133776648863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sfr!2sfr!4v1719229522373!5m2!1sfr!2sfr"
                        width="800" 
                        height="600" 
                        style={{ border: "0", maxWidth: "100%" }} 
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </section>
            </div>
        </div>
    );
}

export default Contact;
