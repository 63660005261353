import './story.css'
import platform from "../img/platform.jpg"
//import { useNavigate } from 'react-router-dom';

function Story() {

    //const navigate = useNavigate()

    //  const handleStoryClick = () => {
    //     navigate('/cobater');
    //      window.scrollTo(0, 0); 
    //  };
    return (
        <div className="content story">
            <div className='text'>
                <h2>Cobater</h2>
                {/* <p className='storyText'> Cobater s'investit depuis 30 ans dans la valorisation des déchets végétaux en composte suivant la réglementation en vigueur.<br/>
            Créée en 1994 par Robert Conchon et initialement basée à Palaiseau, notre société familliale a évolué et est maintenant située sur la commune de Wissous au sud de Paris. <br/>
            <br/>
            Notre plateforme de compostage de déchets végétaux est soumise aux installations classées pour la protection de l'environnement.

            </p>*/}
                <p className='storyText'>Après avoir apporté son savoir-faire auprès des agriculteurs dans leurs projets de développement du compostage, en 1994, Monsieur Robert CONCHON crée COBATER, son activité de compostage de déchets végétaux des parcs et jardins urbains à Palaiseau.<br /><br />

                    En 1996, il installe une plateforme de compostage à Wissous (91) sur un terrain de 2 hectares, acquis avec l'aide de l'ADEME et des services de la DREAL (anciennement DRIRE) de Lisses (91).<br /><br />

                    Depuis 2005, la gestion de l'activité est confiée à ses enfants, qui y sont respectivement investis depuis 1998 et 1994.</p>
                {/*<button className='button buttonStory' onClick={handleStoryClick}>En savoir+</button>*/}

            </div>
            <img className="platformImg" src={platform} alt='la plateforme'></img>
        </div>
    )
}

export default Story;