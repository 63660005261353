import "./method.css"
import { useState } from "react"
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import recycle from "../img/recycle1.jpg"
import recycle2 from "../img/recycle2.jpg"
import broyeuse from "../img/broyeuse.jpg"
import crible from "../img/crible2.jpg"

function Method() {
    const navigate = useNavigate();
    const imgData = [
        { name: "Ramassage des déchets", img: recycle },
        { name: "Ramassage des déchets par l'employé", img: recycle2 },
        { name: "Mise au broyeur", img: broyeuse },
        { name: "Criblage", img: crible },
    ];

    const [current, setCurrent] = useState(0);
    const length = imgData.length;

    const nextSlide = (event) => {
        event.preventDefault();
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = (event) => {
        event.preventDefault();
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    const handleMethodsClick = () => {
        navigate('/methodes');
        window.scrollTo(0, 0);
    };

    if (!Array.isArray(imgData) || imgData.length <= 0) {
        return null;
    }

    return (
        <div className="content method" id="method">
            <h2>Notre Méthode</h2>
            <p>
                Nous utilisons le procédé <b>"Végéterre"</b> mis au point et breveté par l'<b>ADEME</b>.<br />
                Ce procédé de traitement par broyage-compostage demande un stockage spécifique des déchets, un broyage avec un matériel adapté, ainsi qu'un compostage maîtrisé pour une production finale d'un amendement de <b>qualité</b>.<br />
                Celui-ci est riche en matières organiques, magnésie, calcium et sans risque pour les cultures (contrôle INRAE) et répond à la dénomination de <b>"compost végétal"</b> (norme NF U 44-051).<br /><br />
                <h3>Nous procédons en 5 étapes :</h3>
                <ul>
                    <li>Accueil et réception, tri, stockage</li>
                    <li>Déstockage, tri et broyage, mise en andains</li>
                    <li>Fermentation par compostage lent ou rustique et aération par retournement</li>
                    <li>Maturation</li>
                    <li>Criblage du produit fini</li>
                </ul>
            </p>
            <div className="button-container">
                <button className="button buttonMethod" onClick={handleMethodsClick}>+ de détails</button>
            </div>
            <div className="slider">
                <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
                <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
                {imgData.map((img, index) => (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (
                            <img
                                className="imgMethode"
                                src={img.img}
                                alt={img.name}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Method;
