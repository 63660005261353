import Story from "../Story/Story";
import Services from "../Services/Services";
import Products from "../Products/Products";
import Method from "../Method/Method";

function Home() {

    return (
        <div className="layout">
        <Story className=".content" />
            <Services className=".content" />
            <Products className=".content" />
            <Method className=".content" />
    </div>
    )

}

export default Home;