import "./footer.css";
import logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const handleLegalsClick = (event) => {
        event.preventDefault();
        navigate('/mentions-legales');
        window.scrollTo(0, 0);
    };

    const handleContactClick = (event) => {
        event.preventDefault();
        navigate('/contact');
        window.scrollTo(0, 0);
    };

    const handleHomeClick = (event) => {
        event.preventDefault();
        navigate('/');
        window.scrollTo(0, 0);
    };

    return (
        <div className="footer">
            <button onClick={handleContactClick}>Contact</button>
            <img className="logoFooter" src={logo} alt="logo de l'entreprise" onClick={handleHomeClick} />
            <button onClick={handleLegalsClick}>Mentions légales</button>
        </div>
    );
}

export default Footer;
