import "./methods.css"
import woods from "../img/bois.jpg"
import branchs from "../img/branch.jpg"
import broyage from "../img/broyage.jpg"
import fermentation from "../img/fermentation.jpg"
import crible from "../img/crible.jpg"




function Methods() {


  return (
    <div className="content methods">
      <div className="content method pageComplete" >
        <h2>Notre Méthode</h2>
        <p>
          Nous utilisons le procédé <b>"Végéterre"</b> mis au point et breveté par l'<b>ADEME</b>.<br />
          Ce procédé de traitement par broyage-compostage demande un stockage spécifique des déchets, un broyage avec un matériel adapté, ainsi qu'un compostage maîtrisé pour une production finale d'un amendement de <b>qualité</b>.<br />
          Celui-ci est riche en matières organiques, magnésie, calcium et sans risque pour les cultures (contrôle INRAE) et répond à la dénomination de <b>"compost végétal"</b> (norme NF U 44-051).<br /><br />
          <h3>Nous procédons en 5 étapes :</h3>
        </p>
      </div>

      <div className="stepOne">
        <h3> 1. Accueil et réception tri stockage</h3>
        <div className="stepOneTxt">
          <img src={branchs} alt="Tas de branches"></img>
          <p>Nous effectuons une pesée entrée et sortie de chaque véhicule sur notre site. <b />
             Un exemplaire de bon de pesée est remis à chaque chauffeur avec mentions suivantes : Date, heure de passage, numéros d'immatriculation du véhicule, nom et adresse de la société, poids d'entrée et de sortie, poids net déposé ou enlevé, numéro de chantier, lieux d'enlévement des déchets pour suivie de compostage et pour enlèvement de compost ou paillage numéro de lot et conformité NF.
            <br />
            <br />
            L'opérateur de réception contrôle le contenu du véhicule avant chaque déversement effectué sur la plateforme.
            Après validation du contrôle l'opérateur indique au chauffeur du véhicule l'endroit où il doit déverser ses déchets végétaux en fonction de leurs catégories.
            <br />
            <br />

          </p>
        </div>
      </div>
      <div className="stepOneList"><p>Nous avons 3 catégories de produit brut à traiter</p>
        <ul>
          <li>Le bois et souches de gros diamètre (supérieur à 30 cm)</li>
          <li>Les branches issues de l'élagage</li>
          <li>Les tontes de gazon, les feuilles et les tailles d'arbustes et haies</li>
        </ul>
        <img src={woods} alt="Tas de bois"></img>
      </div>

      <div className="steptwo">
        <h3>2. Tri et Broyage mis en andains</h3>
        <div className="stepTwoContent">
          <p>L'opérateur chargé du broyage des déchets effectue un premier tri manuel en vue d'enlever tous déchets non organiques (plastiques, cannettes, ...) qui n'auraient pas été décelés lors du déversement.
            <br />
            Le broyage est ensuite réalisé suivant la quantité de déchets stockés de la même catégorie.
            <br />
            Pour un suivi de compostage la réalisation et mise en andains est faite par lot (un andain est égal à un lot identifié par un numéro soit la réception des déchets pour une période de 3 mois dans l'année).
          </p>
          <div className="">
            <img src={broyage} alt="Ouvrier entrain de mettre les dechet au broyeur"></img>
          </div>
        </div>
      </div>

      <div className="stepThree">
        <h3>3. Fermentation par compostage lent ou rustique et aération par retournement</h3>
        <p>Le lot mit en andains passe en phase de Fermentation active. Hygienisation avec une monté en température importante permettant de garantir un échauffement au cœur  d'au moins 70
          °C pendant 5 jours ce qui permet la destruction des graines adventices. Le retournement, ou aération, est effectué une fois par période de 15 jours par notre chargeur 966F Caterpillar pour une bonne homogénéisation et oxygénation des andains et ainsi eviter des émanations olfactives. <br />
          Un taux d'humidité est maintenu entre 45 % et 50 % grace à un pompage du bassin de ruissellement et aspersion sur les andains en fermentations.
          <br />
          <br />
          La durée de fermentation active est d'environ 3 mois, avec analyse des lots.
        </p>
        <img src={fermentation} alt="Andains en fermentation"></img>
      </div>
      <div className="stepFour">
        <h3>4. Maturation</h3>
        <p>Après 3 mois de fermentation active, l'andain passe en phase de maturation où l'humus est bien présent avec apparition des vers de compost, acariens, cloportes et coléoptères. Nous maintenons des contrôles de température à 30 °C et d'humidité à 50 % ainsi qu'un retournement afin de vérifier sa régularité. <br />
          La phase de repos dure 2 mois.
        </p>
      </div>
      <div className="stepFive">
        <h3>5. Criblage du produit fini</h3>
        <div className="stepFiveContent">
          <img src={crible} alt="Andains en fermentation"></img>

          <p>Après 5 à 6 mois de compostage, le produit arrive en fin de cycle, il est mûr.
            <br />
            <br />
            Les andains sont alors criblés grâce à notre crible à étoile, tamisage de 10 mm à 37 mm en fonction des exigences de nos clients.
            <br />
            <br />
            Compost commercialisé répondant à la norme NFU 44-051
          </p>

        </div>
      </div>


    </div>
  )
}

export default Methods;